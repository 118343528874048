<template>
  <ep-layout>
    <div class="el-panel el-panel--aside-menu mt-n5">
      <div class="el-dialog" v-if="sectionDialog" @click="sectionDialog = false">
        <div class="el-dialog__container el-dialog__container--large" @click.stop>
          <div class="el-panel">
            <el-toolbar :sticky="true">
              <h3 class="el-title">{{ $t('documents.section.title') }}</h3>
            </el-toolbar>
            <div class="el-dialog__panel">
              <el-table
                v-if="icons.filter(x => !documents.map(y => y.code).includes(x.code)).length"
              >
                <el-table-header>
                  <el-table-row>
                    <el-table-head data-type="input" aria-hidden="true"></el-table-head>
                    <el-table-head :main="true">{{ $t('documents.section.table') }}</el-table-head>
                  </el-table-row>
                </el-table-header>
                <el-table-body>
                  <!-- eslint-disable-next-line -->
                  <el-table-row
                    v-for="icon in icons.filter(x => !documents.map(y => y.code).includes(x.code))"
                    :key="icon.text"
                    @click="sectionSelected = icon"
                    :class="{ 'el-table__row--selected': icon === sectionSelected }"
                    :title="icon.text"
                  >
                    <el-table-cell data-type="input" aria-hidden="true">
                      <span :class="'icon-' + icon.value + ' icon--table--orange'"></span>
                    </el-table-cell>
                    <el-table-cell :main="true" >{{ icon.text }}</el-table-cell>
                  </el-table-row>
                </el-table-body>
              </el-table>
              <p class="el-subtitle" v-else>{{ $t('documents.section.no-match') }}</p>
            </div>
            <el-toolbar :bottom="true">
              <button
                class="el-button"
                @click="createSection(sectionSelected)"
                :disabled="!sectionSelected"
              >{{ $t('button.confirm') }}</button>
              <button
                class="el-button el-button--inverted"
                @click="closeSectionDialog()"
              >{{ $t('button.cancel') }}</button>
            </el-toolbar>
          </div>
        </div>
      </div>

      <!-- aside menu -->
      <el-aside-menu :isOpen="isSideMenuOpened" :aria-hidden="sectionDialog">
        <!-- top part without scroll -->
        <el-aside-menu-sticky>
          <el-aside-menu-title>
            <span class="icon-document"></span>
            <span>{{ $t('documents.title') }}</span>
          </el-aside-menu-title>
          <button class="el-button el-button--full-width ep-button__focus" @click="sectionDialog = true">
            <span class="icon-add"></span>
            {{ $t('documents.section.title') }}
          </button>
          <!--
          <button class="el-button el-button--full-width">
            <span class="icon-add"></span>Nouveau
          </button>-->
        </el-aside-menu-sticky>
        <!-- collectors by categorie -->
        <el-aside-menu-label>{{ $t('documents.asideMenu.label') }}</el-aside-menu-label>
        <el-aside-menu-tab
          v-for="(item, index) in documents"
          :key="'documents_' + index"
          :class="{ 'active': item.code === selectedPage }"
          @click.native="goToFolder(item)"
          :title="item.name"
          :aria-label="item.name"
        >
          <template v-if="icons.find(x => x.code === item.code)">
            <span :class="'icon-' + icons.find(x => x.code === item.code).value" ></span>
          </template>
          <template
            v-if="item.code === 'notClassified'"
          >{{ $t('documents.categories.notClassified') }}</template>
          <template v-else>
            {{ item.name }}

            <div class="el-aside-menu__tab__actions no-hover" v-if="editedSection === item">
              <input
                class="el-aside-menu__tab__actions__input"
                type="text"
                ref="editSection"
                :placeholder="$t('documents.rename.placeholder')"
              />
              <button
                class="el-icon-button icon-save"
                @click="renameSection(item)"
                :title="$t('documents.toolbar.button.rename') + ' ' + item.name"
                :aria-label="$t('documents.toolbar.button.rename') + ' ' + item.name"/>
              <button
                class="el-icon-button icon-close"
                @click="editedSection = undefined"
                :title="$t('documents.sharing.create.button.cancel') + ' ' + item.name"
                :aria-label="$t('documents.sharing.create.button.cancel') + ' ' + item.name"/>
            </div>
            <div class="el-aside-menu__tab__actions" v-else tabindex="1">
              <button
                class="el-icon-button icon-edit"
                @click="editedSection = item"
                :title="$t('documents.toolbar.button.rename') + ' ' + item.name"
                :aria-label="$t('documents.sharing.create.button.cancel') + ' ' + item.name"/>
              <button
                class="el-icon-button icon-supprime"
                @click="deleteSection(item)"
                :title="$t('documents.toolbar.button.delete') + ' ' + item.name"
                :aria-label="$t('documents.toolbar.button.delete') + ' ' + item.name"
              />
            </div>
          </template>
          <el-badge :notif="item.isNew" />
        </el-aside-menu-tab>
      </el-aside-menu>
      <slot></slot>
    </div>
  </ep-layout>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import EpLayout from '@/layouts/Default.vue';

export default {

  name: 'FoldersLayout',


  computed: {
    ...mapState({
      isSideMenuOpened: state => state.application.sideMenu.opened,
      documents: state => state.documents.all,
    }),
    ...mapGetters({
      firstbreadcrumb: 'application/getFirstBreadcrumb',
    }),
  },


  data() {
    return {
      selectedPage: '',
      icons: [
        {
          value: 'administratif',
          code: 'administrative',
          text: this.$i18n.t('collector.asideMenu.admin'),
        },
        {
          value: 'banque',
          code: 'banque',
          text: this.$i18n.t('collector.asideMenu.banque'),
        },
        {
          value: 'course',
          code: 'shopping',
          text: this.$i18n.t('collector.asideMenu.shopping'),
        },
        {
          value: 'e-commerce',
          code: 'ecommerce',
          text: this.$i18n.t('collector.asideMenu.e-com'),
        },
        {
          value: 'famille',
          code: 'family',
          text: this.$i18n.t('collector.asideMenu.family'),
        },
        {
          value: 'internet',
          code: 'internet',
          text: this.$i18n.t('collector.asideMenu.internet'),
        },
        {
          value: 'lettre',
          code: 'letters',
          text: this.$i18n.t('collector.asideMenu.letter'),
        },
        {
          value: 'lettre',
          code: 'recordedLetter',
          text: this.$i18n.t('collector.asideMenu.rLetter'),
        },
        {
          value: 'loisir',
          code: 'hobby',
          text: this.$i18n.t('collector.asideMenu.loisir'),
        },
        {
          value: 'maison',
          code: 'home',
          text: this.$i18n.t('collector.asideMenu.home'),
        },
        {
          value: 'folder',
          code: 'myFolders',
          text: this.$i18n.t('collector.asideMenu.myFolders'),
        },
        {
          value: 'signataires',
          code: 'myEmployers',
          text: this.$i18n.t('collector.asideMenu.employer'),
        },
        {
          value: 'sante',
          code: 'health',
          text: this.$i18n.t('collector.asideMenu.health'),
        },
        {
          value: 'transport',
          code: 'transports',
          text: this.$i18n.t('collector.asideMenu.transport'),
        },
        {
          value: 'online',
          code: 'onLineServices',
          text: this.$i18n.t('collector.asideMenu.online'),
        },
        {
          value: 'phone',
          code: 'telephonie',
          text: this.$i18n.t('collector.asideMenu.phone'),
        },
        {
          value: 'notClassified',
          code: 'notClassified',
          text: this.$i18n.t('collector.asideMenu.notClassified'),
        },
      ],
      sectionDialog: false,
      sectionSelected: undefined,
      editedSection: undefined,
    };
  },


  components: {
    EpLayout,
  },

  watch: {
    firstbreadcrumb() {
      if (this.firstbreadcrumb) {
        this.selectedPage = this.firstbreadcrumb.code;
      }
    },
  },


  methods: {
    ...mapActions({
      fetchFilesAndFolders: 'documents/all',
      createFolder: 'documents/createMyFolder',
      renameFolder: 'documents/renameFolder',
      deleteFolder: 'documents/deleteMyFolder',
    }),
    goToFolder(tab) {
      if (tab.code !== this.selectedPage) {
        this.selectedPage = tab.code;
        this.$emit('tabClicked', tab);
      }
    },
    closeSectionDialog() {
      this.sectionDialog = false;
      this.sectionSelected = undefined;
    },
    createSection(section) {
      this.createFolder({
        FolderID: '0',
        NewFolderName: section.text,
        category: section.code,
      }).then(() => {
        this.closeSectionDialog();
        this.fetchFilesAndFolders().then(() => {
          this.$nextTick(() => {
            if (this.firstbreadcrumb) {
              this.selectedPage = this.firstbreadcrumb.code;
            }
          });
        });
      });
    },
    renameSection(section) {
      const name = this.$refs.editSection[0].value;
      this.renameFolder({ folderID: section.folderId, name }).then(() => {
        this.closeSectionDialog();
        this.fetchFilesAndFolders().then(() => {
          this.$nextTick(() => {
            if (this.firstbreadcrumb) {
              this.selectedPage = this.firstbreadcrumb.code;
            }
          });
        });
      });
    },
    deleteSection(section) {
      this.deleteFolder(section.folderId)
        .then(() => {
          this.closeSectionDialog();
          this.fetchFilesAndFolders().then(() => {
            this.$nextTick(() => {
              if (this.selectedPage === section.code) {
                this.goToFolder(this.documents[0]);
              } else if (this.firstbreadcrumb) {
                this.selectedPage = this.firstbreadcrumb.code;
              }
            });
          });
        });
    },
  },
};
</script>
